import React, { useState } from 'react';
import Layout from './Layout';
import axios from 'axios';
const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const HomeInterior = ({ onChange }) => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState({
    fullBaths: '',
    halfBaths: '',
    fireplaces: '',
    fireplaceType: 'gas',
    foundation: 'basement',
    basementPercentFinished: '',
    finishedBasement: 'no',
    walkoutBasement: 'no',
    securitySystem: 'no',
  });

  const onInfoChange = (key, value) => {
    setInfo({ ...info, [key]: value });
    onChange({ ...info, [key]: value });
  };

  return (
    <form
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 0,
      }}
    >
      <label style={{ width: '22%', marginTop: 0, marginRight: 20 }}>
        Full Baths
        <input
          type="text"
          name="baths"
          id="baths"
          onChange={(e) => onInfoChange('fullBaths', e.target.value)}
        />
      </label>
      <label style={{ width: '22%', marginTop: 0, marginRight: 20 }}>
        Half Baths
        <input
          type="text"
          name="halfBaths"
          id="halfBaths"
          onChange={(e) => onInfoChange('halfBaths', e.target.value)}
        />
      </label>
      <label style={{ width: '22%', marginTop: 0, marginRight: 20 }}>
        Fireplaces
        <input
          type="text"
          name="fireplaces"
          id="fireplaces"
          onChange={(e) => onInfoChange('fireplaces', e.target.value)}
        />
      </label>
      {info.fireplaces != '' && (
        <label style={{ width: '22%', marginTop: 0, marginRight: 20 }}>
          Fireplace Type
          <select
            onChange={(e) => onInfoChange('fireplaceType', e.target.value)}
          >
            <option value="gas">Gas</option>
            <option value="wood-burning">Wood Burning</option>
          </select>
        </label>
      )}
      <label style={{ width: '30%', marginTop: 0, marginRight: 20 }}>
        Foundation
        <select onChange={(e) => onInfoChange('foundation', e.target.value)}>
          <option value="basement">Basement</option>
          <option value="crawl">Crawl Space</option>
          <option value="slab">Slab</option>
        </select>
      </label>
      {info.foundation == 'basement' && (
        <label
          style={{
            width: '100%',
            marginTop: 0,
            marginRight: 20,
            marginBottom: 0,
          }}
        >
          Finished Basement <br />
          <input
            type="radio"
            id="basementYes"
            name="finishedBasement"
            onChange={() => onInfoChange('finishedBasement', 'yes')}
          />
          <label htmlFor="basementYes">Yes</label>
          <input
            type="radio"
            id="basementNo"
            name="finishedBasement"
            onChange={() => onInfoChange('finishedBasement', 'no')}
          />
          <label htmlFor="basementNo">No</label>
          {info.finishedBasement == 'yes' && (
            <>
              <label
                style={{
                  width: 200,
                  marginTop: 0,
                  marginRight: 20,
                  fontSize: 16,
                  fontWeight: 'normal',
                }}
              >
                Percent Finished (%)
                <input
                  type="text"
                  name="basementPercentFinished"
                  id="basementPercentFinished"
                  onChange={(e) =>
                    onInfoChange('basementPercentFinished', e.target.value)
                  }
                />
              </label>
            </>
          )}
        </label>
      )}
      {info.foundation == 'basement' && (
        <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
          Walk-out Basement?
          <br />
          <input
            type="radio"
            id="walkoutBasementYes"
            name="walkoutBasement"
            onChange={() => onInfoChange('walkoutBasement', 'yes')}
          />
          <label htmlFor="walkoutBasementYes">Yes</label>
          <input
            type="radio"
            id="walkoutBasementNo"
            name="walkoutBasement"
            onChange={() => onInfoChange('walkoutBasement', 'no')}
          />
          <label htmlFor="walkoutBasementNo">No</label>
        </label>
      )}
      <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
        Security System
        <br />
        <input
          type="radio"
          id="securitySystemYes"
          name="securitySystem"
          onChange={() => onInfoChange('securitySystem', 'yes')}
        />
        <label htmlFor="securitySystemYes">Yes</label>
        <input
          type="radio"
          id="securitySystemNo"
          name="securitySystem"
          onChange={() => onInfoChange('securitySystem', 'no')}
        />
        <label htmlFor="securitySystemNo">No</label>
      </label>
    </form>
  );
};

export default HomeInterior;

import React, { useState } from 'react';
import Layout from './Layout';
import axios from 'axios';
const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const HomeCoverage = ({ onChange }) => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState({
    currentlyInsured: '',
    dogCount: '',
    dogBreed: '',
    dog: 'no',
    desiredEffectiveDate: '',
    anythingElse: '',
  });

  const onInfoChange = (key, value) => {
    setInfo({ ...info, [key]: value });
    onChange({ ...info, [key]: value });
  };

  return (
    <form
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
        Dog?
        <br />
        <input
          type="radio"
          id="dogYes"
          name="dog"
          onChange={() => onInfoChange('dog', 'yes')}
        />
        <label htmlFor="dogYes">Yes</label>
        <input
          type="radio"
          id="dogNo"
          name="dog"
          onChange={() => onInfoChange('dog', 'no')}
        />
        <label htmlFor="dogNo">No</label>
      </label>
      {info.dog == 'yes' && (
        <>
          <label style={{ width: '22%', marginTop: 0, marginRight: 20 }}>
            # of Dogs
            <input
              type="text"
              name="dogCount"
              id="dogCount"
              onChange={(e) => onInfoChange('dogCount', e.target.value)}
            />
          </label>
          <label style={{ width: '22%', marginTop: 0, marginRight: 20 }}>
            Breed(s)
            <input
              type="text"
              name="dogBreed"
              id="dogBreed"
              onChange={(e) => onInfoChange('dogBreed', e.target.value)}
            />
          </label>
        </>
      )}
      <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
        Desired Effective Coverage Date
        <input
          type="date"
          name="desiredEffectiveDate"
          id="desiredEffectiveDate"
          onChange={(e) => onInfoChange('desiredEffectiveDate', e.target.value)}
        />
      </label>
      <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
        Anything Else
        <br />
        <textarea
          name="anything-else"
          id="anything-else"
          placeholder="Additional comments or concerns?"
          rows="6"
          onChange={(e) => onInfoChange('anythingElse', e.target.value)}
        ></textarea>
      </label>
    </form>
  );
};

export default HomeCoverage;

import React, { useState } from 'react';
import Layout from './Layout';
import axios from 'axios';
const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const HomeExterior = ({ onChange }) => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState({
    garageType: 'no-garage',
    garageSize: '1',
    deck: 'no',
    screenedInPorch: 'no',
    pool: 'no',
  });

  const onInfoChange = (key, value) => {
    setInfo({ ...info, [key]: value });
    onChange({ ...info, [key]: value });
  };

  return (
    <form
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      <label style={{ width: '40%', marginTop: 0, marginRight: 20 }}>
        Garage Type
        <select onChange={(e) => onInfoChange('garageType', e.target.value)}>
          <option value="no-garage">No Garage</option>
          <option value="attached">Attached</option>
          <option value="basement">Basement</option>
          <option value="built-in">Built-in</option>
          <option value="built-in">Built-in (living space above)</option>
          <option value="carport">Car Port</option>
          <option value="detached">Detached</option>
        </select>
      </label>
      <label style={{ width: '40%', marginTop: 0, marginRight: 20 }}>
        Garage Size
        <select onChange={(e) => onInfoChange('garageSize', e.target.value)}>
          <option value="0">--</option>
          <option value="1">Single Car</option>
          <option value="2">Two Car</option>
          <option value="3">Three Car</option>
          <option value="4">Four Car</option>
        </select>
      </label>

      <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
        Deck?
        <br />
        <input
          type="radio"
          id="deckYes"
          name="deck"
          onChange={() => onInfoChange('deck', 'yes')}
        />
        <label htmlFor="deckYes">Yes</label>
        <input
          type="radio"
          id="deckNo"
          name="deck"
          onChange={() => onInfoChange('deck', 'no')}
        />
        <label htmlFor="deckNo">No</label>
      </label>
      <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
        Screened-in Porch?
        <br />
        <input
          type="radio"
          id="screenedInPorchYes"
          name="screenedInPorch"
          onChange={() => onInfoChange('screenedInPorch', 'yes')}
        />
        <label htmlFor="screenedInPorchYes">Yes</label>
        <input
          type="radio"
          id="screenedInPorchNo"
          name="screenedInPorch"
          onChange={() => onInfoChange('screenedInPorch', 'no')}
        />
        <label htmlFor="screenedInPorchNo">No</label>
      </label>
      <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
        Pool?
        <br />
        <input
          type="radio"
          id="poolYes"
          name="pool"
          onChange={() => onInfoChange('pool', 'yes')}
        />
        <label htmlFor="poolYes">Yes</label>
        <input
          type="radio"
          id="poolNo"
          name="pool"
          onChange={() => onInfoChange('pool', 'no')}
        />
        <label htmlFor="poolNo">No</label>
      </label>
    </form>
  );
};

export default HomeExterior;
